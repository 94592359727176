<template>
    <table class="setting" cellpadding="0" cellspacing="0">
      <thead>
        <div style="width:100%">
          <tr>
            <th class="setting_select"></th>
            <th class="setting_direction"><i style="color: #F56C6C;margin-right: 0.21rem;margin-left: 0.5rem;">*</i>货物或应税劳务名称</th>
            <th class="setting_value">规格型号</th>
            <th class="setting_subject">单位</th>
            <th class="setting_subject">数量</th>
            
            <th class="setting_subject">单价{{taxIncluded?'(含税)':'(不含税)'}}</th>
            <th class="setting_value"><i style="color: #F56C6C;margin-right: 0.21rem;">*</i>金额{{taxIncluded?'(含税)':'(不含税)'}} 
              <span class="change" @click="changeTax">切换</span>
            </th>
            <th class="setting_value"><i style="color: #F56C6C;margin-right: 0.21rem;">*</i>税率</th>
            <th class="setting_value"><i style="color: #F56C6C;margin-right: 0.21rem;">*</i>税额</th>
          </tr>
        </div>
      </thead>
      <tbody>
          <el-checkbox-group v-model="checkList" class="checkboxGroup">
              <tr class="setting_item" style="width:100%" v-for="(data,index) in value.fyxm" :key="data.id" >
                <el-checkbox  :label="data.id" class="checkbox" :disabled="category=='2'">
                <td class="setting_direction"> <!-- 商品名称 -->
                  <!-- <el-input v-model='data.spmc'  maxlength="100" :disabled="data.disabled || category=='2'"/>  -->
                  <el-select v-model="data.spmc" placeholder="请选择" filterable class="settingSelect" @change="handleSelect(data)" :disabled="data.disabled || category=='2'">
                    <el-option v-for="item in restaurants" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </td>
                <td class="setting_value"> <!-- 规格型号 -->  
                  <el-input v-model='data.ggxh' maxlength="10" v-if="!data.discount" :disabled="data.disabled || category=='2'"/>
                </td>
                <td class="setting_subject"> <!-- 单位 -->
                  <el-input v-model='data.dw' maxlength="5" v-if="!data.discount" :disabled="data.disabled || category=='2'"/>
                </td>
                <td class="setting_subject"> <!-- 数量 -->
                  <el-input-number :controls='false' :precision='2' :min="category=='2'?-999999999.99:0" :max="999999999" v-model="data.spsl" maxlength="11" @blur="numPriceChange(data)"  v-if="!data.discount" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input-number>
                </td>
                <td class="setting_subject"> <!-- 单价 -->
                  <!-- <el-input  v-model="data.dj"  maxlength="11" @input="checkInput(data)" v-if="!data.discount" @blur="numPriceChange(data)" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input> -->
                  <el-input-number :controls='false' :precision='2' :min="0" :max="999999999"  v-model="data.dj" maxlength="11" v-if="taxIncluded && !data.discount"  @blur="numPriceChange(data)" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input-number>
                  <el-input-number :controls='false' :precision='2' :min="0" :max="999999999"  v-model="data.bhsdj" maxlength="11" v-if="!taxIncluded && !data.discount"  @blur="numPriceChange(data)" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input-number>
                </td>
                <td class="setting_value"> <!-- 金额 -->
                  <el-input-number :controls='false' :precision='2' :min="category=='2' || data.discount?-999999999.99:0" :max="999999999"  v-model="data.je" maxlength="12" v-if="taxIncluded" @blur="taxRateChange(data)" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input-number>
                  <el-input-number :controls='false' :precision='2' :min="category=='2' || data.discount?-999999999.99:0" :max="999999999"  v-model="data.bhsje" maxlength="12" v-if="!taxIncluded" @blur="rateTaxChange(data)" :disabled="data.disabled || category=='2'" class="proportion">
                  </el-input-number>
                </td>
                <td class="setting_value"><!-- 税率 -->
                  <el-select v-model="data.sl" placeholder="请选择" filterable class="settingSelect" @change="rateChange(data)" :disabled="data.disabled || category=='2'">
                    <el-option v-for="item in DEFAULT_INVOICE_TAXRATE" :key="item.value" :label="item.name + '%'" :value="item.value">
                    </el-option>
                  </el-select>
                </td>
                <td class="setting_value"><!-- 税额 -->
                  <el-input type="text" maxlength="11" v-model="data.se"  @blur="checkBlur(data)" disabled/>
                </td>
                <!-- 未开具发票 和 税控机动车专用发票 不作列表展示 -->
                </el-checkbox>
                <i class="iconfont iconicon1-21" @click="add(index,data)" v-if="category!='2'"></i>
                <i class="iconfont iconicon-12" @click="del(index,data)"  v-if="category!='2'"></i>
              </tr>
          </el-checkbox-group>
      </tbody>
      <tr class="total">
        <div class="totalTitle">
            合计
        </div>
        <div style="display: flex;">
          <div class="totalCount" v-if="!taxIncluded" :class="category=='2'?'red':''">
              ¥{{amount}}
          </div>
          <div class="totalCount">
          </div>
          <div class="totalCount" :class="category=='2'?'red':''">
              ¥{{taxAmount}}
          </div>
        </div>
      </tr>
      <tr class="total">
        <div class="totalTitle">
            价税合计(大写)
        </div>
        <div style="display: flex;">
          <div class="totalText" :class="category=='2'?'red':''">
              {{totalPriceTax | toChies}}
          </div>
          <div class="totalText" :class="category=='2'?'red':''">
              (小写)¥{{totalPriceTax}}
          </div>
        </div>
      </tr>

      <discount-dialog :visible='discountVis' :cancelDialog='cancelDiscount' :confirmDialog='confirmDiscount'
        :assistingData='assistingData' :assistingList='assistingList' :taxIncluded='taxIncluded' />

    </table>
</template>


<script>
  import VMoneyFormat from '@/directive/moneyFormat';
  import { plusOrMinus , decimalPoint} from '@/utils'
  import discountDialog from './dialog/discount'        //导入凭证功能
  const uuid = require('uuid');
  export default {
    props: {
      value: Object,
      category:{
        type: String,
      },
      disabled:{
        type: Boolean,
        default: () => {
          return false
        }
      },
      config: {
        type: Object,
        default: () => {
          return { precision: 2, thousands: ',' }
        }
      }
    },
    components:{
      discountDialog
    },
    directives: { MoneyFormat: VMoneyFormat },
    filters: {	//局部过滤器	
      toChies: function (money) {
        let head = money < 0 ? '负' : '';
        //汉字的数字
        let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
        //基本单位
        let cnIntRadice = new Array('', '拾', '佰', '仟');
        //对应整数部分扩展单位
        let cnIntUnits = new Array('', '万', '亿', '兆');
        //对应小数部分单位
        let cnDecUnits = new Array('角', '分', '毫', '厘');
        //整数金额时后面跟的字符
        let cnInteger = '整';
        //整型完以后的单位
        let cnIntLast = '元';
        //最大处理的数字
        let maxNum = 999999999999999.9999;
        //金额整数部分
        let integerNum;
        //金额小数部分
        let decimalNum;
        //输出的中文金额字符串
        let chineseStr = '';
        //分离金额后用的数组，预定义
        if (money < 0) {
          money = Math.abs(money)
        }
        let parts;
        if (money == '') { return ''; }
        money = parseFloat(money);
        if (money >= maxNum) {
          //超出最大处理数字
          return '';
        }
        if (money == 0) {
          chineseStr = cnNums[0] + cnIntLast + cnInteger;
          return chineseStr;
        }
        //转换为字符串
        money = money.toString();
        if (money.indexOf('.') == -1) {
          integerNum = money;
          decimalNum = '';
        } else {
          parts = money.split('.');
          integerNum = parts[0];
          decimalNum = parts[1].substr(0, 4);
        }
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
          var zeroCount = 0;
          var IntLen = integerNum.length;
          for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == '0') {
              zeroCount++;
            } else {
              if (zeroCount > 0) {
                chineseStr += cnNums[0];
              }
              //归零
              zeroCount = 0;
              chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
              chineseStr += cnIntUnits[q];
            }
          }
          chineseStr += cnIntLast;
        }
        //小数部分
        if (decimalNum != '') {
          var decLen = decimalNum.length;
          // eslint-disable-next-line no-redeclare
          for (var i = 0; i < decLen; i++) {
            // eslint-disable-next-line no-redeclare
            var n = decimalNum.substr(i, 1);
            if (n != '0') {
              chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
          }
        }
        if (chineseStr == '') {
          chineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (decimalNum == '') {
          chineseStr += cnInteger;
        }
        return head + chineseStr;
      },
    },
    data() {
      return {
        taxIncluded:true,
        checkList:[],
        amount :0,
        taxAmount :0,
        totalPriceTax :0,
        restaurants:[], //商品列表
        DEFAULT_INVOICE_TAXRATE:[
            { name: 17, value: 17 },
            { name: 16, value: 16 },
            { name: 13, value: 13 },
            { name: 11, value: 11 },
            { name: 10, value: 10 },
            { name: 9, value: 9 },
            { name: 6, value: 6 },
            { name: 5, value: 5 },
            { name: 4, value: 4 },
            { name: 3, value: 3 },
            { name: 1, value: 1 },
            { name: 0, value: 0 },
        ],  //发票税率 征税项目

        // 折扣弹窗
        discountVis:false,
        assistingData:{},
        assistingList:[],
      }
    },
    methods: {
      querySearch(){
        this.$store.dispatch("QueryCommodity", {
          name:'',
          companyId: this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.restaurants = res.data;
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {

        })
      },
      handleSelect(value) {
        // 选中商品信息，回显参数处理
        let item = this.restaurants.find((items) => { return items.name == value.spmc }) 
        // let item = this.restaurants.find((items) => { return items.spbm == value.categoryCode }) 
        value.sl = item.taxRate * 1
        value.spbm = item.categoryCode
        value.ggxh = item.spec
        value.dw = item.unit
        value.dj = item.price 
        value.bhsdj = item.exclusiveTaxPrice
        value.lslbs = item.freeTaxType
        value.yhzcbs = item.hasPreferPolicy
        value.zzstsgl = item.preferPolicyType
        this.rateChange(value)
      },
      checkInput(data) {
        data.spsl = plusOrMinus(data.spsl);
        data.dj = plusOrMinus(data.dj);
        data.je = plusOrMinus(data.je);
        data.se = plusOrMinus(data.se);
        data.bhsje = plusOrMinus(data.bhsje);
        data.bhsdj = plusOrMinus(data.bhsdj);
      },
      checkBlur(data){
        data.se = decimalPoint(data.se);
        data.spsl = decimalPoint(data.spsl);
      },
      numPriceChange(row){
        let sl = row.sl ? row.sl : 0
        if(this.taxIncluded){  //含税计算
          if ( row.dj != undefined && row.spsl != undefined &&  row.spsl !== ''  && row.dj !== ''  &&  row.spsl != '0.00'  && row.dj != '0.00' ){
            row.je = (row.spsl * row.dj).toFixed(2)
            row.se = (row.je * (sl / 100)).toFixed(2)
          }else if(row.dj != undefined && row.je != undefined &&  row.je !== '' &&  row.je != '0.00' && row.dj !== '' && row.dj != '0.00'){
            row.spsl = (row.je / row.dj).toFixed(2)
          }else if(row.je != undefined && row.spsl != undefined &&  row.spsl !== '' &&  row.spsl != '0.00'  && row.je !== '' &&  row.je != '0.00'){
            row.dj = (row.je / row.spsl).toFixed(2)
          }
        }else{  //不含税计算
          if ( row.bhsdj != undefined && row.spsl != undefined &&  row.spsl !== ''  && row.bhsdj !== '' &&  row.spsl != '0.00'  && row.dj != '0.00'){
            row.bhsje = (row.spsl * row.bhsdj).toFixed(2)
            row.se = (row.bhsje * (sl / 100)).toFixed(2)
          }else if(row.bhsdj != undefined && row.bhsje != undefined &&  row.bhsje !== ''  && row.bhsdj !== '' &&  row.bhsje != '0.00'  && row.bhsdj != '0.00'){
            row.spsl = (row.bhsje / row.bhsdj).toFixed(2)
          }else if(row.bhsdj != undefined && row.spsl != undefined &&  row.spsl !== ''  && row.bhsdj !== '' &&  row.spsl != '0.00'  && row.bhsdj != '0.00'){
            row.bhsdj = (row.bhsje / row.spsl).toFixed(2)
          }
        }
        this.rateChange(row)
        this.$forceUpdate()
      },
      taxRateChange(row) {  //含税计算
        let sl = row.sl ? row.sl : 0
        let price = row.je / ( 1 + (sl / 100) )  //原价
        if(price!= undefined && price != '' && !Number.isNaN(price)){
          row.se = (price * (sl / 100)).toFixed(2)
        }
        if(row.je != undefined && row.je !== '' && row.je != '0.00' && row.spsl != undefined &&  row.spsl !== '' && row.spsl != '0.00' ){  //含税单价
          if(row.spsl*1 == 0){
            row.dj  = 0
          }else{
            row.dj = (row.je / row.spsl).toFixed(2) // 含税单价
          }
        }else if(row.dj != undefined && row.je != undefined &&  row.je !='0.00' &&  row.je !== ''  && row.dj !== '' && row.dj != '0.00'){
          if(row.dj*1 == 0){
             row.spsl  = 0
          }else{
            row.spsl =  (row.je / row.dj).toFixed(2);
          }
        }
        row.bhsje = price.toFixed(2)
        if(row.dj != undefined && row.dj != '' &&  row.dj !='0.00' ){ // 得到不含税 - 税额
          row.bhsdj = (row.dj / ( 1 + (sl / 100) )).toFixed(2)   // 不含税单价
        }
        this.$forceUpdate()
      },
      rateTaxChange(row) {  //不含税计算
        let sl = row.sl ? row.sl : 0
        if(row.bhsje != undefined && row.spsl != undefined &&  row.spsl !== ''  && row.bhsje !== '' &&  row.spsl != '0.00'  && row.bhsje != '0.00'){  //不含税单价
          if(row.spsl*1 == 0){
            row.bhsdj  = 0
          }else{
            row.bhsdj = (row.bhsje / row.spsl).toFixed(2) // 不含税单价
          }
        }else if(row.bhsdj != undefined && row.bhsje != undefined &&  row.bhsje !='0.00' &&   row.bhsje !== ''  && row.bhsdj !== '' && row.bhsdj != '0.00'){
          if(row.bhsdj*1 == 0){
             row.spsl  = 0
          }else{
            row.spsl =  (row.bhsje / row.bhsdj).toFixed(2);
          }
        }
        if(row.bhsje != undefined && row.bhsje != '' &&  row.bhsje !='0.00' ){ // 得到不含税 - 税额
          row.se = (row.bhsje * (sl / 100)).toFixed(2)     // 税额
        }
        row.je = (row.bhsje * 1 + row.se * 1).toFixed(2)  //含税金额
        if(row.je != undefined && row.spsl != undefined &&  row.spsl !== ''  && row.je !== ''){  //反推含税单价
          if(row.spsl*1 == 0){
            row.dj  = 0
          }else{
            row.dj = (row.je / row.spsl).toFixed(2) // 含税单价
          }
        }

        this.$forceUpdate()
      },
      rateChange(data){   //更改税率
        if(this.taxIncluded){  //含税
          this.taxRateChange(data)
        }else{
          this.rateTaxChange(data)
        }
      },
      add(index,item) {
        if(item.haveDiscount){
          einvAlert.warning('提示','数据中包含被折扣行信息,请先删除折扣信息','确定','我知道了')
          return false
        }
        this.value.fyxm.splice(index + 1, 0, {
          id: uuid.v1(),
          fphxz:'0',    //发票行性质 0 正常行;1 折扣行;2 被折扣行
          se:'',       //税额		
          sl:'',       //税率		
          spbm:'',     //商品编码			
          spmc:'',     //商品名称	
          xh:'',			 //行号
          dw:'',       //单位	
          ggxh:'',     //规格型号	

          spsl:undefined,     //商品数量
          dj:undefined,       //单价
          bhsdj:undefined,    //不含税单价
          je:undefined,       //金额	
          bhsje:undefined,    //不含税金额

          lslbs:'',
          yhzcbs:'',
          zzstsgl:'',

          discount : false,
          haveDiscount : false,
          disabled:false,
          relationId: '', // 折扣行关联ID
        })
      },

      del(key, item) {
        if (this.value.fyxm.length > 1) {//数据不少于1行
          if(item.haveDiscount){
            einvAlert.warning('提示','数据中包含被折扣行信息,请先删除折扣信息','确定','我知道了')
            return false
          }
          if(item.relationId){  // 删除折扣行
            this.value.fyxm.map((items)=>{
              if(items.id == item.relationId){
                items.haveDiscount = false;
                items.disabled = false;
                items.fphxz = '0';//发票行性质 0 正常行;1 折扣行;2 被折扣行
              }
            }) 
          }

          let index = this.checkList.findIndex((items)=> items == item.id) 
          if(index != '-1'){
            this.checkList.splice(index, 1);
          }
          console.log(this.checkList); // 处理选中被删除
          this.value.fyxm.splice(key, 1);
        } else {
          this.value.fyxm.splice(key, 1);  //先删除 后尾部添加
          this.value.fyxm.push({
            id: uuid.v1(),
            se:'',       //税额		
            sl:'',       //税率		
            spbm:'',     //商品编码			
            spmc:'',     //商品名称	
            xh:'',			 //行号
            dw:'',       //单位	
            ggxh:'',     //规格型号	
            spsl:undefined,     //商品数量
            dj:undefined,       //单价
            bhsdj:undefined,    //不含税单价
            je:undefined,       //金额	
            bhsje:undefined,    //不含税金额
            discount : false,
            haveDiscount : false,
            disabled:false,
            relationId: '', // 折扣行关联ID
            lslbs:'',
            yhzcbs:'',
            zzstsgl:'',
          })
        }
      },

      changeTax(){
        this.taxIncluded = !this.taxIncluded
        this.value.hsbz = this.taxIncluded ? '1' : '0'
      },

      initValue() {  //初始化借方 贷方余额
        debugger
        let list = JSON.parse(JSON.stringify(this.value.fyxm));
        let amount = 0, taxAmount = 0, bhshjje = 0;
        let status = false;
        let hsbz = list[0].hsbz; // 发票明细的含税标志
        this.taxIncluded = hsbz=='0'?false:true; // add by dj at 2023-08-18 税控服务器返回的默认为不含税，初始化需做处理
        list.map((item) => {
          if(item.sl == '3'){
            status = true
          }
          if(this.taxIncluded){
            amount  += (item.je && item.je !=undefined && item.je !='NaN' ? item.je : 0) * 1;
          }else{
            amount  += (item.bhsje && item.bhsje !=undefined && item.bhsje !='NaN' ? item.bhsje : 0) * 1;
          }
          bhshjje += (item.bhsje && item.bhsje !=undefined&& item.bhsje !='NaN'? item.bhsje : 0 ) * 1;
          taxAmount += (item.se?item.se:0) * 1;
        })

        this.amount = Number.isNaN(amount.toFixed(2)) ? 0 : amount.toFixed(2) //金额
        this.taxAmount = Number.isNaN(taxAmount.toFixed(2)) ? 0 : taxAmount.toFixed(2);//税额
        this.totalPriceTax = this.taxIncluded ? amount.toFixed(2) : (amount * 1 + taxAmount * 1).toFixed(2); //价税总计

        this.value.hjje = this.amount
        this.value.bhshjje = Number.isNaN(bhshjje.toFixed(2)) ? 0 : bhshjje.toFixed(2) //金额
        this.value.hjse = this.taxAmount
        this.value.jshj = this.totalPriceTax

        this.$emit('changeSl',status)
      },

      // 新增折扣
      showDiscount(){
        // console.log(this.checkList,'this.checkList');
        if(this.checkList.length == 0){
          einvAlert.warning('提示','请选择需要打折的的数据','确定','我知道了')
          return false
        }else if(this.checkList.length > 1){
          einvAlert.warning('提示','只能选择一行商品添加折扣','确定','我知道了')
          return false
        }else if(this.checkList.length == 1){
          let item = this.value.fyxm.find((item)=> item.id == this.checkList[0]) 
          if(item.discount){
            einvAlert.warning('提示','不能选中折扣行','确定','我知道了')
            return false
          }
          if(item.haveDiscount){
            einvAlert.warning('提示','商品已添加折扣信息,请重新选择','确定','我知道了')
            return false
          }
          if(item.spmc && item.sl && item.je){  // 满足条件弹窗
            this.discountVis = true; 
            // 向上统计 统计所有折扣行
            let itemIndex = this.value.fyxm.findIndex((items)=> items.id == this.checkList[0]) 
            // console.log(itemIndex,'统计角标');
            let totalPrice = 0;
            this.assistingList = [];
            this.value.fyxm.map((item,index)=>{
              if(index <= itemIndex && !item.haveDiscount && !item.discount && !item.disabled && (item.spmc && item.sl && item.je)){
                this.assistingList.push({...item,index})
                if(this.taxIncluded){
                  totalPrice  += (item.je?item.je:0) * 1;
                }else{
                  totalPrice  += (item.bhsje?item.bhsje:0) * 1;
                }
              }
            })
            // console.log(newList,'newList');
            this.assistingData = {
               amount : totalPrice.toFixed(2),
               line : this.assistingList.length,
               max: this.assistingList.length,
               rate :'',
               discount: '0.00',
            }

          }else{
            einvAlert.warning('提示','请添加折扣商品信息','确定','我知道了')
            return false
          }
        }
      },
      cancelDiscount(){
        this.discountVis = false; 
      },
      confirmDiscount(data,changeArr){
        // 生成折扣逻辑
        console.log(data,changeArr);
        this.discountVis = false; 

        // this.value.splice(item.index, 0, {  //原本行列
        //   ...item,
        //   haveDiscount : true,
        //   discount : false,
        // })
        
        changeArr.map((item,index)=>{
         let list = this.value.fyxm.map((datas)=>{           //原本行列
            if(datas.id == item.id){
              return {
                ...datas,
                fphxz:'2',//发票行性质 0 正常行;1 折扣行;2 被折扣行
                haveDiscount : true,
                discount : false,
                disabled:true,
              }
            }else{
              return {
                ...datas
              }
            }
          })
          this.value.fyxm = list  // 添加折扣条件
          debugger
          this.value.fyxm.splice(item.index + index + 1, 0, {  //新增行数
            id: uuid.v1(),
            // spsl:undefined,     //商品数量
            // dj:undefined,       //单价
            // bhsdj:undefined,    //不含税单价
            
            je: (item.je * (data.rate / 100) * -1).toFixed(2),                                             //不含税金额
            bhsje: (item.je / ( 1 + (item.sl / 100) )* ((data.rate / 100) * -1)).toFixed(2),       
            se : (item.je / ( 1 + (item.sl / 100) ) * (item.sl / 100) * ((data.rate / 100) * -1)).toFixed(2),   //税额

            sl : item.sl,    //税率

            spmc : '*'+item.categoryName+'*'+item.name,  //商品名称
            spbm:'',     //商品编码			
            xh:'',			 //行号
            dw:'',       //单位	
            ggxh:'',     //规格型号	

            lslbs: item.lslbs,
            yhzcbs: item.yhzcbs,
            zzstsgl: item.zzstsgl,

            relationId: item.id,
            discount : true,
            haveDiscount : false,
            disabled:true,
            fphxz:'1',//发票行性质 0 正常行;1 折扣行;2 被折扣行
          })
        })
      },
    },
    mounted(){
      this.querySearch();
    },
    watch:{
      'value.fyxm':{
        handler(val, oldVal) {
          this.initValue();
        },
        deep: true,
        immediate: true
      },
      'taxIncluded':{
        handler(val, oldVal) {
          this.initValue();
        },
        deep: true,
        immediate: true
      },
    }
  }
</script>

<style lang="less" scoped>
  .setting {
    border-collapse: collapse;
    position: relative;
    margin: 0 auto;
    th{
      padding: 6px;
      border: 1px solid #DCDCDC !important;
    }
    td {
      padding: 6px;
      height: 35px;
      border-left: 1px solid #DCDCDC !important;
      border-right: 1px solid #DCDCDC !important;
      // .proportion {
      //   width: 98%;
      //   margin-right: 1%;
      //   /deep/.el-input__inner {
      //     text-align: right;
      //   }
      // }
    }
    th {
      height: 40px;
      line-height: 40px;
      background: #F6F6F6;
      color: #333;
      font-size: 14px;
      text-align: center;
      font-weight: normal;
    }
    .checkboxGroup{
      min-height: 200px;
    }
    .checkbox{
      height: 50px;
      /deep/ .el-checkbox__input{
        width: 50px;
        vertical-align: top;
        text-align: center;
        margin-top: 18px;
      }
    }
    .setting_select{
      width: 60px;
      word-break: break-all;
      word-wrap: break-word;
      text-align: left;
    }
    .setting_direction {
      width: 220px;
      word-break: break-all;
      word-wrap: break-word;
      text-align: left;
    }
    .setting_subject {
      width: 159px; 
      word-break: break-all;
      word-wrap: break-word;
      position: relative;
    }

    /deep/ .el-input-number--medium{
        width: 100% !important;
    }
    /deep/ .el-input-number .el-input__inner {
        text-align: left; //位置居左
    }
    // .setting_subjectOne{
    //   width: 250px; 
    //   word-break: break-all;
    //   word-wrap: break-word;
    //   position: relative;
    // }
    .setting_value{
      width: 188.5px;
      word-break: break-all;
      word-wrap: break-word;
      position: relative;
    }
    .change{
      color: #F1C32C;
      cursor: pointer;
    }
    .settingSelect{
      width: 100% !important;
    }
    .setting_item {
      position: relative;
      border-bottom: 1px solid #dcdcdc;
    }
    .setting_item:hover {
      .iconicon1-21 {
        cursor: pointer;
        opacity: 1;
      }
      .iconicon-12 {
        cursor: pointer;
        opacity: 1;
      }
    }
    .total{
      height: 50px;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      display: flex;
      justify-content: space-between;
      .totalTitle{
        color: #222;
        height: 2.6rem;
        width: 220px;
        text-indent: 10px;
        font-size: 0.83rem;
        margin-left: 60px;
        line-height: 2.6rem;
        border-right: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc;
      }
      .totalCount{
        color: #222;
        height: 50px;
        width: 189px;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        border-left: 1px solid #dcdcdc;
      }
      .totalText{
        color: #222;
        height: 50px;
        width: 31.64rem;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        // border-left: 1px solid #dcdcdc;
      }
      .red{
        color: #FF0000 !important;
      }
    }
    .iconicon1-21 {
      position: absolute;
      left: -20px;
      margin-top: 14px;
      font-size: 20px;
      color: #35c4b1;
      opacity: 0;
    }
    .iconicon-12 {
      position: absolute;
      margin-top: 14px;
      font-size: 20px;
      color: #ff0000;
      opacity: 0;
    }
  }
</style>

